import {
  createBrowserRouter,
  Navigate,
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import { Button, ConfigProvider, Space } from 'antd';

import "./App.css"
import Dashboard from "./dashboard"
import Product from "./product"
import About from "./about"
import Ship from "./ship"
import Inquiry from "./inquiry"

export default function App() {
  return (
    <div className="App">
      <ConfigProvider
    theme={{
      token: {
        // Seed Token，影响范围大
        colorPrimary: '#FF954D',
      },
    }}
      >
        <Toaster />
      <BrowserRouter>
          <Routes>
                <Route path="/" element={<Dashboard/>}></Route>
                <Route path="/product" element={<Product/>}></Route>
                <Route path="/about" element={<About/>}></Route>
                <Route path="/ship" element={<Ship/>}></Route>
                <Route path="/inquiry/:type" element={<Inquiry/>}></Route>
                <Route path="*" element={<Navigate to='/'/>}></Route>
          </Routes>
      </BrowserRouter>
  </ConfigProvider>
            
    </div>
  );
}
