import cs from "classnames"
import moduleCss from "./index.module.scss";

import { Modal, Button, Timeline, Table} from 'antd';

export default function Part(props) {
    const showModal = () => {
        props.setIsModalOpen(true);
    };

    const handleOk = () => {
        props.setIsModalOpen(false);
    };

    const handleCancel = () => {
        props.setIsModalOpen(false);
    };


    const columns = [
        {
          title: 'Cost Name',
          dataIndex: 'Cost Name',
            key: 'Cost Name',
        //   width: 120,
          render: (text) => <div className="tabletext">{text}</div>,
        },
        {
          title: 'Unit Price',
          dataIndex: 'Unit Price',
            key: 'Unit Price',
            // width: 150,
            render: (text) => <div className="tabletext">{text}</div>,

        },
        {
          title: 'Quantity',
            dataIndex: 'Quantity',
        //   width: 150,
            key: 'Quantity',
            render: (text) => <div className="tabletext">{text}</div>,

        },
        {
          title: 'Unit',
          key: 'Unit',
            dataIndex: 'Unit',
        //   width: 150,
          render: (text) => <div className="tabletext">{text}</div>,

        },
        {
          title: 'Cost',
          key: 'Cost',
            dataIndex: 'Cost',
        //   width: 150,
          render: (text) => <div className="tabletext bold">{text}</div>,

        },
      ];
      const data = [
        {
          'Cost Name': 'THC',
          'Unit Price': 'CNY 600',
          'Quantity': '2',
          'Unit': 'box',
          'Cost': 'CNY 600',
          },
          {
            'Cost Name': 'Visa fees',
            'Unit Price': 'CNY 600',
            'Quantity': '2',
            'Unit': 'box',
            'Cost': 'CNY 600',
          },
        
          {
            'Cost Name': 'Booking fee',
            'Unit Price': 'CNY 600',
            'Quantity': '2',
            'Unit': 'box',
            'Cost': 'CNY 600',
          },
        
          {
            'Cost Name': 'EIR',
            'Unit Price': 'CNY 600',
            'Quantity': '2',
            'Unit': 'box',
            'Cost': 'CNY 600',
          },
    ];
    
    const footerNode = (<div className="footer">
        <span>total</span>
        <span>CNY 3600</span>
    </div>)
    return (<>
        <Modal title="Potential costs at the port of departure"
            className={moduleCss.detailcostmodal}
            footer={null}
            open={props.isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}>
            <div className={moduleCss.detailCost}>
                <div className="top">
                    {/* <div className="titlep">Potential costs at the port of departure</div> */}
                    <div className="pro">
                        <Table style={{ width: '600px' }} columns={columns}
                            dataSource={data}
                            pagination={null}
                            footer={() => {return (<div className="footer">
                            <span>total</span>
                            <span>CNY 3600</span>
                        </div>)}} />
                    </div>
                    <div className="titlep">Potential costs</div>
                    <div className="pro">
                    <Table style={{ width: '600px' }} columns={columns}
                            dataSource={data}
                            pagination={null}
                            footer={() => {return (<div className="footer">
                            <span>total</span>
                            <span>CNY 3600</span>
                        </div>)}} />
                    </div>
                </div>
                <div className="down">
                    <div className="p1">
                        <div className="a1">Single piece cost</div>
                        <div className="a2">$150,000</div>
                    </div>
                    <div className="p2">
                        <div className="a1">Single piece cost</div>
                        <div className="a2">$150,000</div>
                    </div>
                </div>
            </div>
      </Modal>
    </>)
}