import { Modal, Radio, Select, Input, Flex } from "antd";
import ProductDetail from "./productdetail.module.scss";

const selectAfter = (
  <Select defaultValue="USD">
    <Select.Option value=".com">USD</Select.Option>
    <Select.Option value=".jp">.jp</Select.Option>
    <Select.Option value=".cn">.cn</Select.Option>
    <Select.Option value=".org">.org</Select.Option>
  </Select>
);

const option = [
  {
    value: "jack",
    label: "Jack",
  },
  {
    value: "lucy",
    label: "Lucy",
  },
  {
    value: "Yiminghe",
    label: "yiminghe",
  },
  {
    value: "disabled",
    label: "Disabled",
  },
];
const ModalProductDetail = (props) => {
  return (
    <Modal
      open={props.open}
      onCancel={props.close}
      afterClose={props.close}
      maskClosable={false}
      className={ProductDetail["product_detail"]}
      footer={null}
    >
      <div className={ProductDetail["detail-header"]}>Product 1</div>
      <div className={ProductDetail["special-list"]}>
        <div className={ProductDetail["detail-title"]}>
          <span> Product code</span>
          <Input />
        </div>
        <div className={ProductDetail["detail-title"]}>
          <span> Product Name</span>
          <Input />
        </div>
      </div>
      <div className={ProductDetail["detail-title"]}>
        <span>
          <i>*</i>Packing information
        </span>
        <div className={ProductDetail["list-sublist"]}>
          <div className={ProductDetail["sublist-item"]}>
            <div className={ProductDetail["item-list-title"]}>Number</div>
            <Input />
          </div>
          <div className={ProductDetail["sublist-item"]}>
            <div className={ProductDetail["item-list-title"]}>Total weight</div>
            <Input suffix="kg" />
          </div>
        </div>
      </div>
      <div className={ProductDetail["detail-title"]}>
        <span>
          <i>*</i>Individual product specifications
        </span>
        <div className={ProductDetail["list-sublist"]}>
          <div className={ProductDetail["sublist-item"]}>
            <div className={ProductDetail["item-list-title"]}>Long</div>
            <Input suffix="cm" />
          </div>
          <div className={ProductDetail["sublist-item"]}>
            <div className={ProductDetail["item-list-title"]}>Width</div>
            <Input suffix="cm" />
          </div>
          <div className={ProductDetail["sublist-item"]}>
            <div className={ProductDetail["item-list-title"]}>Height</div>
            <Input suffix="cm" />
          </div>
          <div className={ProductDetail["sublist-item"]}>
            <div className={ProductDetail["item-list-title"]}>Gross weight</div>
            <Input suffix="kg" />
          </div>
        </div>
      </div>

      <div className={ProductDetail["detail-title"]}>
        <span>
          <i>*</i>Individual product specifications
        </span>
        <div className={ProductDetail["list-sublist"]}>
          <div className={ProductDetail["sublist-item"]}>
            <div className={ProductDetail["item-list-title"]}>Quantity</div>
            <Input suffix="cm" />
          </div>
          <div className={ProductDetail["sublist-item"]}>
            <div className={ProductDetail["item-list-title"]}>Total price</div>
            <Input addonAfter={selectAfter} />
          </div>
        </div>
      </div>

      <div className={ProductDetail["special-list"]}>
        <div className={ProductDetail["detail-title"]}>
          <span>
            <i>*</i>Packaging method
          </span>
          <Select
            defaultValue="lucy"
            style={{ width: "100%" }}
            options={option}
          />
        </div>
        <div className={ProductDetail["detail-title"]}>
          <span> Packing method</span>
          <Select
            defaultValue="lucy"
            style={{ width: "100%" }}
            options={option}
          />
        </div>
        <div className={ProductDetail["detail-title"]}>
          <span> Is it load-bearing</span>
          <Flex vertical gap="middle">
            <Radio.Group defaultValue="a" buttonStyle="solid">
              <Radio.Button value="a">Yes</Radio.Button>
              <Radio.Button value="d">No</Radio.Button>
            </Radio.Group>
          </Flex>
        </div>
      </div>
      <div className={ProductDetail["special-list"]}>
        <div className={ProductDetail["detail-title"]}>
          <span> Encoding requirements</span>
          <Select
            defaultValue="lucy"
            style={{ width: "100%" }}
            options={option}
          />
        </div>
        <div className={ProductDetail["detail-title"]}>
          <span> Packaging status</span>
          <Select
            defaultValue="lucy"
            style={{ width: "100%" }}
            options={option}
          />
        </div>
      </div>
      <div className={ProductDetail["detail-footer"]}>
         <span >Delete</span>
        <div className={ProductDetail["detail-btn-preserve"]} onClick={() => props.close()}>Preserve</div>
      </div>
    </Modal>
  );
};

export default ModalProductDetail;
