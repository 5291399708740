import cs from "classnames"
import moduleCss from "./index.module.scss";
import Part from "./../../part"

import { Modal, Button, Timeline,Collapse, Divider, } from 'antd';
import arrowora_png from "@/assets/img/inquery/arrow.png"
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useState } from "react";

export default function Modal2(props) {
    const showModal = () => {
        props.setIsModalOpen(true);
    };

    const handleOk = () => {
        props.setIsModalOpen(false);
    };

    const handleCancel = () => {
        props.setIsModalOpen(false);
    };


    const detailCostData = [
        {title: 'Unit price', value: '$1000',},
        {title: 'Number', value: '1',},
        {title: 'Tax refund rate', value: '10%',},
        {title: 'Single piece cost', value: '10%',},
        {title: 'Customs clearance tax rate', value: '10%',},
        {title: 'Anti dumping tax rate', value: '10%',},
        {title: 'Overall cost', value: '$10,000',},
    ]
    const [activeKey, setactiveKey] = useState(['1'])

    const updateActivekey = (key) => {
        let i = activeKey.indexOf(key)
        if (i == -1) {
            activeKey.push(key)
            setactiveKey([...activeKey])

        } else {
            activeKey.splice(i, 1)
            setactiveKey([...activeKey])
        }

    }
    const CollapseData = [
        {
            key: '1',
            showArrow:false,
            label: (<div>
                        <div className="row">
                            <div className="title">PANYU</div>
                            <img className="arrow" src={ arrowora_png} />
                            <div className="title">GUANGZHOU</div>
                            <img className="arrow" src={ arrowora_png} />
                            <div className="title">Istanbul</div>
                            <img className="arrow" src={ arrowora_png} />
                            <div className="title">Destination</div>
                        </div>
                        <div className="down">
                            <div className="dl">
                                <div className="d1">
                                    <div className="title">Trip</div>
                                    <div className="td">
                                        <span>20</span>
                                        <span className="day">day</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="title">Total cost</div>
                                    <div className="td">
                                        <span>$150,000</span>
                                    </div>
                                </div>
                            </div>
                            <div className="dr">
                                <Button icon={activeKey.includes('1') ? <UpOutlined/> :<DownOutlined />} onClick={() => updateActivekey('1')}>Retract</Button>
                                <Button className="b2" onClick={handleOk}>Choice</Button>
                            </div>
                        </div>
            </div>),
            children: <Part/>
        },
        {
            key: '2',
            showArrow:false,
            label: (<div>
                        <div className="row">
                            <div className="title">PANYU</div>
                            <img className="arrow" src={ arrowora_png} />
                            <div className="title">GUANGZHOU</div>
                            <img className="arrow" src={ arrowora_png} />
                            <div className="title">Istanbul</div>
                            <img className="arrow" src={ arrowora_png} />
                            <div className="title">Destination</div>
                        </div>
                        <div className="down">
                            <div className="dl">
                                <div className="d1">
                                    <div className="title">Trip</div>
                                    <div className="td">
                                        <span>20</span>
                                        <span className="day">day</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="title">Total cost</div>
                                    <div className="td">
                                        <span>$150,000</span>
                                    </div>
                                </div>
                            </div>
                            <div className="dr">
                                <Button icon={<DownOutlined />} onClick={() => updateActivekey('2')}>Retract</Button>
                                <Button className="b2" onClick={handleOk}>Choice</Button>
                            </div>
                        </div>
            </div>),
            children: <Part/>
        },
    ]

    const onChangeCollapse = (value) => {
        console.log(value);
        // setactiveKey(value)
    }

    return (<>
        <Modal title="Transfer plan"
            className={moduleCss.detailcostmodal}
            footer={null}
            open={props.isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}>
            <div className={moduleCss.detailCost}>
                <div className="top">
                    <Collapse
                        ghost
                        activeKey={activeKey}
                        onChange={onChangeCollapse}
                    items={CollapseData}
                    />
                </div>
            </div>
      </Modal>
    </>)
}