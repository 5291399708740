import moduleCss from "./home.module.scss";
import cssQuote from "./quote.module.scss";

import cs from "classnames"
import { useState, useEffect, useRef, useCallback, } from "react";
import * as _ from "lodash"
import toast from "react-hot-toast";

import { Swiper, SwiperSlide, } from 'swiper/react';
import { Autoplay, } from 'swiper/modules';
import { Input, Select,DatePicker,Modal, Form,Checkbox,Button} from 'antd';
import Head from "./../header"
import Foot from "./../footer"
import http from "@/util/http";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import "./swiperhome.css"

const { TextArea } = Input;


const goodsType = [
    {
        "label": "普货(General Cargo)",
        "value": 10205
    },
    {
        "label": "一般化工品(General Chemicals)",
        "value": 10206
    },
    {
        "label": "危险品(Dangerous goods)",
        "value": 10207
    },
    {
        "label": "冷冻冷藏(Refrigerated)",
        "value": 10208
    },
    {
        "label": "特种柜及大件货(Special Equipment and Bulk Goods)",
        "value": 10209
    },
    {
        "label": "冷危(Refrigerated Dangerous Goods)",
        "value": 10210
    },
    {
        "label": "私人物品(Personal Effects)",
        "value": 10211
    },
    {
        "label": "贵重物品(Valuables)",
        "value": 10212
    },
    {
        "label": "超重超长货物(Overweight&Overlength Cargo)",
        "value": 17521
    },
    {
        "label": "挂衣(Hanging Clothes)",
        "value": 17522
    },
    {
        "label": "药品(Drugs)",
        "value": 17523
    },
    {
        "label": "易腐生鲜(Perishable And Fresh)",
        "value": 17524
    },
    {
        "label": "整车(Vehicle)",
        "value": 17525
    },
    {
        "label": "电子产品(Electronic Products)",
        "value": 17526
    }
]
export default function ModalQuote(props) {
    const [form] = Form.useForm();
    const [startPortOptions, setStartPortOptions] = useState([]);
    const [endPortOptions, setEndPortOptions] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [startPort, setStartPort] = useState();
    const [endPort, setEndPort] = useState();
    const [qunatity, setQunatity] = useState();
    const [qunatityOptions, setQunatityOptions] = useState([]);
    const [startTime, setStartTime] = useState();
    const fetchStartPort = (value, callback) => { 
        http.get("/port/list", { portName: value }).then((res) => {
            callback(res.data);
        });
    }
    const handleSearchStartPort = useCallback(_.debounce((newValue) => {
        fetchStartPort(newValue, setStartPortOptions);
    },
    500,
        {
            leading: false,
            trailing: true
        },
    ), [])
    const handleChangesStartPort = (newValue) => {
        form.setFieldValue('startPort',newValue)
    };

    const fetchEndPort = (value, callback) => {
        http.get("/port/list", { portName: value }).then((res) => {
            callback(res.data);
        });
     }
    const handleSearchEndPort = useCallback(_.debounce((newValue) => {
        fetchEndPort(newValue, setEndPortOptions);
    },
    500,
        {
            leading: false,
            trailing: true
        },
    ), [])
    const handleChangesEndPort = (newValue) => {
        form.setFieldValue('endPort', newValue)
    };

    const fetchCountry = (value, callback) => {
        http.get(`/base/country/list`, { countryName: value }).then((res) => {
            callback(res.data);
        });
    }
    const handleSearchCountry = (newValue) => {
        fetchCountry(newValue, setCountryOptions);
    };

    const handleChangesCountry = (newValue) => {
        form.setFieldValue('country',newValue);
    };
    

    const handleChange_qunatity = v => {
        setQunatity(v);
    }
    

    const [dateValue, setDateValue] = useState();

    const handleOk = useCallback(() => {
        form.resetFields()
        props.close()
    }, [])
    const handleCancel = useCallback(() => { 
        form.resetFields()
        props.close()
    }, [])

    const onFinish = (values) => {
        console.log('Success:', values);
      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    

    const [check_ensure, setCheck_ensure] = useState(false);
    const [Submit_btn_loading, setSubmit_btn_loading] = useState(false);
    const onSubmit = () => {
        setSubmit_btn_loading(true)
        form.validateFields().then(async (r) => {
            let rawValue = form.getFieldsValue()
            console.log(rawValue, r,);
            setSubmit_btn_loading(false)
            toast.success("Submit Success!")
            props.close()
        }).catch((e) => {
            setSubmit_btn_loading(false)
            console.log(e)
        })
    }

    useEffect(() => {
       
     }, [])
  return (
    <Modal
        open={props.open}
        title="Get My Quote"
        onOk={handleOk}
        onCancel={handleCancel}
          footer={null}
          destroyOnClose={true}
          afterClose={handleCancel}
          className={cssQuote["quote_modal"]}
      >
        <Form
              name="basic"
              form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
              className={cssQuote.form_basic}
          >
              <div className="block">
                  <div className="tip">Quote Details</div>
                  <div className="content">
                      <div className="left">
                          <div>
                            <Form.Item
                                name="startPort"
                                label="Port of Loading"
                                rules={[
                                {
                                    required: true,
                                },
                                ]}
                                className="item1"
                            >
                                <Select
                                    showSearch
                                    placeholder="Start Port"
                                    onSearch={handleSearchStartPort}
                                    onChange={handleChangesStartPort}
                                      notFoundContent={null}
                                      filterOption={false}
                                    options={startPortOptions.map((d) => ({
                                        value: d.portCode,
                                        label: d.portName,
                                    }))}
                                    />
                            </Form.Item>
                            
                            <Form.Item
                                name="endPort"
                                label="Port of Destination"
                                rules={[
                                {
                                    required: true,
                                },
                                ]}
                            >
                                <Select
                                      showSearch
                                      filterOption={false}
                                    placeholder="End Port"
                                    onSearch={handleSearchEndPort}
                                    onChange={handleChangesEndPort}
                                    notFoundContent={null}
                                    options={endPortOptions.map((d) => ({
                                        value: d.portCode,
                                        label: d.portName,
                                    }))}
                                    />
                            </Form.Item>
                          </div>
                          <div>
                                <Form.Item
                                    name="Cntr Type"
                                    label="Cntr Type"
                                    rules={[
                                    {
                                        required: true,
                                    },
                                    ]}
                                    className="item1"
                                >
                                    <Select
                                        placeholder="Cntr Type"
                                        onChange={handleChange_qunatity}
                                            options={goodsType}
                                            
                                        />
                                </Form.Item>
                                <Form.Item
                                    name="dateValue"
                                    label="Sailing Date"
                                    rules={[
                                    {
                                        required: true,
                                    },
                                    ]}
                                >
                                    <DatePicker />
                                </Form.Item>
                          </div>
                    </div>
                    <div className="right">
                    <Form.Item
                            name="remark"
                            label="Remark"
                            rules={[
                            {
                                required: false,
                            },
                            ]}
                        >
                            <TextArea placeholder="Please note the following information: delivery time, weight of goods, general or chemical products, and other special requirements"/>
                        </Form.Item>
                    </div>
                  </div>
              </div>
              <div className="block">
              <div className="tip">Your Company Information</div>
                  <div className="content">
                  <Form.Item
                        name="country"
                        label="Country or Region of the Company"
                        rules={[
                        {
                            required: true,
                        },
                      ]}
                      className="item1"
                    >
                        <Select
                              showSearch
                              filterOption={false}
                            placeholder="Please Select"
                            onSearch={handleSearchCountry}
                            onChange={handleChangesCountry}
                            notFoundContent={null}
                            options={countryOptions.map((d) => ({
                                value: d.countyCode,
                                label: d.countyName,
                            }))}
                            />
                    </Form.Item>
                    <Form.Item
                        name="type"
                        label="Company Business Type"
                        rules={[
                        {
                            required: true,
                        },
                          ]}
                          className="item1 item20"
                    >
                        <Select
                            placeholder="Please Select"
                              options={[
                                  { label: 'Freight forwarder', value: '1' },
                                  { label: 'Importer/Exporter', value: '2' },
                              ]}
                            />
                  </Form.Item>
                  <Form.Item
                        name="CompanyName"
                        label="Company Name"
                        rules={[
                        {
                            required: true,
                        },
                        ]}
                    >
                        <Input placeholder="Please enter company name"/>
                            
                    </Form.Item>
              </div>
                  
              </div>
              <div className="block">
                <div className="tip">Your Personal Information</div>
                <Form.Item
                            name="Email"
                            label="Email"
                            rules={[
                            {
                                required: true,
                            },
                            ]}
                        >
                            <Input placeholder="Please enter"/>
                        </Form.Item>
              </div>
              <div className="down">
              <Form.Item
                            name="check"
                      label={ null}
                            rules={[
                            {
                                    required: true,
                                message:"Please agree to relevant laws and regulations first",
                            },
                      ]}
                      valuePropName="checked"
                  >
                      
                  <Checkbox>
                      <span className="note">Agree with <span className="hightlight">Service Terms</span> & <span className="hightlight">Privacy Policy</span></span>
                  </Checkbox>
                  </Form.Item>
              </div>
              <div className="down">
                  <Button className="Submit_btn" onClick={onSubmit} loading={ Submit_btn_loading}>Submit Now</Button>
              </div>

            
        </Form>
    </Modal>
  );
}
