import { Modal, Input, Select } from "antd";
import cssTransferplan from "./transferplan.module.scss";
import Modal2 from "@/pc/inquiry/ddp/modal2"
import { useState } from "react";

const option = [
  {
    value: "jack",
    label: "Jack",
  },
  {
    value: "lucy",
    label: "Lucy",
  },
  {
    value: "Yiminghe",
    label: "yiminghe",
  },
  {
    value: "disabled",
    label: "Disabled",
  },
];

const ModalTransferPlan = (props) => {
  const [isModal2, setisModal2] = useState(false)
  const openModal2 = (status) => {
      setisModal2(status)
  }
  return (
    <>
    <Modal
      open={props.open}
      afterClose={props.close}
      onCancel={props.close}
      maskClosable={false}
      className={cssTransferplan["transfer_plan"]}
      footer={null}
    >
      <div className={cssTransferplan["header"]}>DDP transfer plan </div>
      <div className={cssTransferplan["item-title"]}>
        <span>Type of shipping</span>
        <a>Select from history</a>
      </div>
      <div className={cssTransferplan["subitem-list"]}>
        <div className={cssTransferplan["subitem-list-item"]}>
          <Select
            style={{width:'100%'}}
            defaultValue="lucy"
            // onChange={handleChange}
            options={option}
          />
        </div>
      </div>
      <div className={cssTransferplan["item-title"]}>
        <span>Shipping Address</span>
      </div>
      <div className={cssTransferplan["subitem-list"]}>
        <div className={cssTransferplan["subitem-list-item"]}>
          <label>Province</label>
          <Select
            style={{width:'100%'}}
            defaultValue="lucy"
            // onChange={handleChange}
            options={option}
          />
        </div>
        <div className={cssTransferplan["subitem-list-item"]}>
          <label>City</label>
          <Select
            style={{width:'100%'}}
            defaultValue="lucy"
            // onChange={handleChange}
            options={option}
          />
        </div>
        <div className={cssTransferplan["subitem-list-item"]}>
          <label>Area</label>
          <Select
            style={{width:'100%'}}
            defaultValue="lucy"
            // onChange={handleChange}
            options={option}
          />
        </div>
        <div className={cssTransferplan["subitem-list-item"]}>
          <label>Specific address</label>
          <Input placeholder="Please enter" />
        </div>
      </div>
      <div className={cssTransferplan["item-title"]}>
        <span>Receiving address</span>
        <a>Select from history</a>
      </div>
      <div className={cssTransferplan["subitem-list"]}>
        <div className={cssTransferplan["subitem-list-item"]}>
          <label>Country</label>
          <Select
            style={{width:'100%'}}
            defaultValue="lucy"
            // onChange={handleChange}
            options={option}
          />
        </div>
        <div className={cssTransferplan["subitem-list-item"]}>
          <label>Province</label>
          <Select
            style={{width:'100%'}}
            defaultValue="lucy"
            // onChange={handleChange}
            options={option}
          />
        </div>
        <div className={cssTransferplan["subitem-list-item"]}>
          <label>City</label>
          <Select
            style={{width:'100%'}}
            defaultValue="lucy"
            // onChange={handleChange}
            options={option}
          />
        </div>
        <div className={cssTransferplan["subitem-list-item"]}>
          <label>PostCode</label>
          <Input placeholder="Please enter" />
        </div>
        <div className={cssTransferplan["subitem-list-item"]}>
          <label>Specific address</label>
          <Input placeholder="Please enter" />
        </div>
      </div>
        <div className={cssTransferplan["btn-query"]} onClick={() => { props.close(); openModal2(true); }}>Intelligent Query</div>
    </Modal>
    <Modal2 isModalOpen={isModal2} setIsModalOpen={openModal2} />
</>
  );
};

export default ModalTransferPlan;
