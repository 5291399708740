import PC from './pc/home/home.js';
import Mobile from './h5/home/home.js';

export default function App() { 
    let flag = window.navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|mbian|Windows Phone)/i
    );
    if(flag){
        return <Mobile />
    } else {
        return <PC />
    }
}