import Part from "./part"
import DetailCost from "./detailedcost"
import Transferplan from "./transferplan"

import cs from "classnames"
import moduleCss from "./index.module.scss";
import { useState,useRef } from "react";
import { Link , useParams,  } from "react-router-dom";

import { Select, InputNumber, Tabs, Input,Divider,Button,Collapse,} from 'antd';
import { RedoOutlined,PlusOutlined } from '@ant-design/icons';
import arrowora_png from "@/assets/img/inquery/arrow.png"
import ViewDetailModal from "./ViewDetailModal"
import ModalProductDetail from "@/pc/modal/ModalProductDetail"
import ModalTransferPlan from "@/pc/modal/ModalTransferPlan"



  
export default function Inquiry() { 
    const tags = ['CIF', 'FOB', 'DDP', 'DDU', 'EXW',]
    const  [CollapseData, setCollapseData] = useState([])
    const newTabIndex = useRef(4);
    let { type } = useParams();
    let type_index = tags.findIndex(e => e == type)
    const [activeTagIndex, setActiveTagIndex] = useState(type_index)

    const tabContent = (<div className="panelc">
    <div className="row">
        <div>
            <div className="tip">Product code</div>
            <Input/>
        </div>
        <div>
            <div className="tip">Product Name</div>
            <Input/>
        </div>
    </div>
    <div>
        <div className="tip">Packing information</div>
        <Button type="primary" style={{width: '50%'}} onClick={() => openModalProductDetail(true)}>Input</Button>
    </div>
</div>)
const initialItems = [
    {
      label: 'Product 1',
      children: tabContent,
      key: '1',
    },
    {
      label: 'Product 2',
      children: tabContent,
      key: '2',
    },
    {
      label: 'Product 3',
      children: tabContent,
      key: '3',
    //   closable: false,
    },
];
    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };
    const changeActiveTag = (value) => {
        setActiveTagIndex(value)
        if (tags[value] != 'DDP') {
            // let params = `ship?start=${startPort}&sname=${urls.startPort.label}&end=${endPort}&ename=${urls.endPort.label}&type=${qunatity}&time=${dayjs(dateValue).format("YYYY-MM-DD")}`
            // let url = document.URL + params;
            // window.open(url) //此处的url是全路径
        }
    };
    

    const [addonValue, setAddonValue] = useState([1,1,1,1,1,])

    const plusAddonValue = (i) => {
        addonValue[i] += 1
        setAddonValue([...addonValue])
    }
    const decAddonValue = (i) => {
        if (addonValue[i] > 0) {
            addonValue[i] -= 1
            setAddonValue([...addonValue])
        } 
    }

    const addOnBefore = (index) => {
        return (
            <div onClick={() => plusAddonValue(index)}>+</div>
        )
    }

    const decOnBefore = (index) => {
        return (
            <div onClick={() => decAddonValue(index)}>-</div>
        )
    }


    const [activeKey, setActiveKey] = useState(initialItems[0].key);
    const [items, setItems] =  useState(initialItems);
    const onChangeTabCard = (newActiveKey) => {
        setActiveKey(newActiveKey);
    };
    const add = () => {
        const newActiveKey = `product${newTabIndex.current++}`;
        const newPanes = [...items];
        newPanes.push({
            label: 'new product',
            children: tabContent,
            key: newActiveKey,
        });
        setItems(newPanes);
        setActiveKey(newActiveKey);
        };
    const remove = (targetKey) => {
        let newActiveKey = activeKey;
        let lastIndex = -1;
        items.forEach((item, i) => {
            if (item.key === targetKey) {
            lastIndex = i - 1;
            }
        });
        const newPanes = items.filter((item) => item.key !== targetKey);
        if (newPanes.length && newActiveKey === targetKey) {
            if (lastIndex >= 0) {
            newActiveKey = newPanes[lastIndex].key;
            } else {
            newActiveKey = newPanes[0].key;
            }
        }
        setItems(newPanes);
        setActiveKey(newActiveKey);
    };
    const onEdit = (targetKey, action) => {
        if (action === 'add') {
          add();
        } else {
          remove(targetKey);
        }
    };


    const [isModalOpenDetailedcost, setIsModalOpenDetailedcost] = useState(false)
    const [isModalopenTransferplan, setIsModalopenTransferplan] = useState(false)
    const [isViewDetailModal, setViewDetailModal] = useState(false)
    const [isModalProductDetail, setModalProductDetail] = useState(false)
    const [isModalTransferPlan, setModalTransferPlan] = useState(false)
    const openViewDetailModal = (status) => {
        setViewDetailModal(status)
    }
    const openDetailedcost = (status) => {
        setIsModalOpenDetailedcost(status)
    }
    const openTransferplan = (status) => {
        setIsModalopenTransferplan(status)
    }
    const openModalProductDetail = (status) => {
        setModalProductDetail(status)
    }
    const openModalTransferPlan = (status) => {
        setModalTransferPlan(status)
    }


    const Collapseitems = [
        {
          key: '1',
            label: (<>
                <div className="add-item">
              <div>Product1</div>
              <div>10000</div>
              <div>100 pieces</div>
              <div>100KG</div>
                </div>
                <Divider/>
            </>),
          children: <p></p>,
        },
        {
            key: '2',
            label: (<>
                <div className="add-item">
              <div>Product2</div>
              <div>10000</div>
              <div>100 pieces</div>
              <div>100KG</div>
                </div>
                <Divider/>
            </>),
            children: <p></p>,
          },
        
      ];
    
    
    


    

    return (
        <div className={moduleCss.inquiryPage}>
            <div className="page">
                <div className="h1">Inquiry information</div>
                <div className="content">
                    <div className="leftc">
                        <div className="h3">Transportation terms</div>

                        <div className="boxs">
                            {tags.map((e, i) => {
                                return (
                                    <Link  to={"/inquiry/" + e} key={i} className={cs('item', {'item-active': i == activeTagIndex }) }>
                                    {e}
                                    </Link >
                                )
                            })}
                        </div>
                        <div className="h3">Route itinerary</div>
                        {/* <Part/> */}
                        <div className="row-1">
                            <div className="title">GUANGZHOU</div>
                            <img className="arrow" src={arrowora_png} />
                            <div className="title">ISTANBUL</div>
                            <img className="arrow" src={arrowora_png} />
                            <div className="title">Malaysia</div>
                        </div>
                        <div className="h3">Product Information</div>
                        <div style={{marginBottom: '30px'}}>
                            <Button style={{width: '50%', marginRight: '12px', border: '1px solid #FF954D', color: '#FF954D'}}>Upload product</Button>
                            <a className="ora" href="javascript:0">Download template</a>
                        </div>

                        <Collapse expandIconPosition={'end'}  ghost items={Collapseitems} />
                        <Button icon={<PlusOutlined /> } style={{width: '100%'}} onClick={() => openModalProductDetail(true)}>Add</Button>
                        <div className="h3">Summarize</div>

                        <div className="box1">
                            <div className="boxl">
                                <div className="item">
                                    <div className="name">Total</div>
                                    <div className="value">10</div>
                                </div>
                                <div className="line"></div>
                                <div className="item">
                                    <div className="name">Total volume </div>
                                    <div className="value">10m³</div>
                                </div>
                                <div className="line"></div>

                                <div className="item">
                                    <div className="name">Total weight</div>
                                    <div className="value">10 Kg</div>
                                </div>
                                <div className="line"></div>

                                <div className="item">
                                    <div className="name">Containers</div>
                                    <div className="value">10</div>
                                </div>
                            </div>
                            <div className="boxr">
                                <Button icon={<RedoOutlined />}>Re estimate</Button>
                            </div>
                        </div>
                        
                    </div>
                    <div className="rightc" style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                        <div>

                        
                        {/* <div className="h3">Transportation terms</div>
                        <Tabs
                        type="editable-card"
                        onChange={onChangeTabCard}
                        activeKey={activeKey}
                        onEdit={onEdit}
                        items={items}
                        />
                        <Divider />
                         */}
                        <div className="h3" style={{ display: 'flex', justifyContent: 'space-between'}}><span>Container information</span><a className="ora" href="javascript:0">Introduction to Container Information</a></div>
                        <div style={{marginBottom: '10px', display: 'flex', justifyContent: 'space-between'}}>
                        <Select
                            defaultValue=""
                            style={{
                                width: 200,
                            }}
                            onChange={handleChange}
                            options={[
                                {
                                value: 'jack',
                                label: 'Jack',
                                },
                                {
                                value: 'lucy',
                                label: 'Lucy',
                                },
                                {
                                value: 'Yiminghe',
                                label: 'yiminghe',
                                },
                                {
                                value: 'disabled',
                                label: 'Disabled',
                                disabled: true,
                                },
                            ]}
                        />
                        <Button className="btn" style={{width: '46%'}} type="primary">Intelligent estimation</Button>

                        </div>
                        
                        <div className="gp20">
                            <div className="gp-left">
                                <div className="item">
                                    <div className="n1">20GP</div>
                                    <InputNumber addonBefore={decOnBefore(0)} addonAfter={addOnBefore(0)} value={addonValue[0]} />
                                </div>
                                <div className="item">
                                    <div className="n1">40HQ</div>
                                    <InputNumber addonBefore={decOnBefore(1)} addonAfter={addOnBefore(1)} value={addonValue[1]} />
                                </div>
                                <div className="item">
                                    <div className="n1">45HQ</div>
                                    <InputNumber addonBefore={decOnBefore(2)} addonAfter={addOnBefore(2)} value={addonValue[2]} />
                                </div>
                            </div>
                            <div className="20gp-right">
                                <div className="item">
                                    <div className="n1">40GP</div>
                                    <InputNumber addonBefore={decOnBefore(3)} addonAfter={addOnBefore(3)} value={addonValue[3]} />
                                </div>
                                <div className="item">
                                    <div className="n1">40NOR</div>
                                    <InputNumber addonBefore={decOnBefore(4)} addonAfter={addOnBefore(4)} value={addonValue[4]} />
                                </div>
                            </div>
                            </div>
                        </div>
                        <div>
                            <div className="h3">Product cost（For guidance only）</div>
                            <div className="box1 box2">
                            <div className="boxl">
                                <div className="item">
                                    <div className="name">Total</div>
                                    <div className="value">10</div>
                                </div>
                                <div className="line"></div>

                                <div className="item">
                                    <div className="name">Total volume </div>
                                    <div className="value">10m³</div>
                                </div>
                            </div>
                            
                            <div className="boxr">
                                <Button onClick={() => openDetailedcost(true)}>Detailed cost</Button>
                                <Button onClick={() => openTransferplan(true)}>Transfer plan</Button>
                            </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="bottom">
                    <div className="left">Estimated total cost</div>
                    <div className="right">
                        <div className="leftod">
                            <div className="bold">$150,000</div>
                            <div className="light">USD 8000 , CNY 2000</div>
                        </div>
                        <Button className="btn1" onClick={() => openViewDetailModal(true)}>detail</Button>
                        <Button type="primary" className="btn2">Create inquiry form</Button>
                    </div>
                </div>
            </div>
            <DetailCost isModalOpen={isModalOpenDetailedcost} setIsModalOpen={ openDetailedcost}/>
            <Transferplan isModalOpen={isModalopenTransferplan} setIsModalOpen={ openTransferplan}/>
            <ViewDetailModal isModalOpen={isViewDetailModal} setIsModalOpen={openViewDetailModal} />
            
            <ModalProductDetail open={isModalProductDetail} close={ () => openModalProductDetail(false)} />
            <ModalTransferPlan open={isModalTransferPlan} close={ () => openModalTransferPlan(false)} />

        </div>

    )
}