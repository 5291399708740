
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

import Head from "./../header"
import Foot from "./../footer"

import moduleCss from "./index.module.scss";
import p1_png from "@/assets/img/product/p1.png";
import p2_png from "@/assets/img/product/p2.png";
import p3_png from "@/assets/img/product/p3.png";
import p4_png from "@/assets/img/product/p4.png";
import p5_png from "@/assets/img/product/p5.png";
import p6_png from "@/assets/img/product/p6.png";

import p11_png from "@/assets/img/product/p11.png";
import p22_png from "@/assets/img/product/p22.png";
import p33_png from "@/assets/img/product/p33.png";
import p44_png from "@/assets/img/product/p44.png";
import p55_png from "@/assets/img/product/p55.png";
import p66_png from "@/assets/img/product/p66.png";

export default function Product() {
    const cards = [
        {
            title: 'FCL Sevices',
            items: [
                {
                    img: p1_png,
                    img_2: p11_png,
                    title: 'Schedule & Rates',
                    desc: `We provide you transparency, flexibility and accuracy in our solutions to all
                    your logistical needs <br>Search, Book & Freight All-in-one<br>Fixed POL & POD Surcharge<br>Show the best option base on coustomer's preference.`,
                },
                {
                    img: p2_png,
                    img_2: p22_png,

                    title: 'Terms of Trade',
                    desc: `We do<br>
                    FOB：Free On Board<br>
                    CIF: Cost,Insurance and Freight<br>
                    EXW: EX-Works<br>
                    DDU : Delivered Duty Unpaid<br>
                    DAP: Delivered At Place<br>
                    DDP: Delivered Duty Paid`, 
                },
            ],
        },
        {
            title: 'SaaS Platform',
            items: [
                {
                    img: p3_png,
                    img_2: p33_png,

                    title: 'Manage your own dashboard',
                    desc: `Cargoes tracking showing on map<br>
                    To-Do tasks<br>
                    Configure Your Subscriptions<br>
                    Quick Entry<br>
                    My B/L`,
                },
                {
                    img: p4_png,
                    img_2: p44_png,

                    title: 'Feight Tracking',
                    desc: `Cargo Detail Info<br>
                    Cargo Useage Time<br>
                    Truck & Load Detail<br>
                    Declaration & Clearance Detail<br>`, 
                },
            ],
        },
        {
            title: 'Free Online Tools',
            items: [
                {
                    img: p5_png,
                    img_2: p55_png,

                    title: 'Vessel Schedule & Tracker',
                    desc: `Port Status<br>
                     Arrival Notice<br>
                     Berthing Sequence<br>
                     Support 20+ shipping companies<br>`,
                },
                {
                    img: p6_png,
                    img_2: p66_png,

                    title: 'HS Code Lookup',
                    desc: `Quick Search<br>
                    Provide Latest Tax Rate<br>
                    Inspection And Quarantine Category<br>`, 
                },
            ],
        },
    ]
    return (
        <>
            <Head/>
            <div className={moduleCss.banner}>
                <p>Product</p>
            </div>
            {
                cards.map((card, index) => {
                    return (
                        <div key={index} className={moduleCss.card}>
                            <div className={moduleCss.title}>{card.title}</div>
                            <div className={moduleCss.items}>
                                {
                                    card.items.map((item, index) => {
                                        if (index == 0) {
                                            return (
                                                <div key={index} className={moduleCss.card_item}>
                                                    <div className={moduleCss.text}>
                                                        <div className={moduleCss.text_title}>{item.title}</div>
                                                        <div className={moduleCss.desc} dangerouslySetInnerHTML={{ __html: item.desc }}/>
                                                    </div>
                                                    <PhotoProvider>
                                                        <PhotoView src={item.img_2}>
                                                            <img src={item.img} alt="" />
                                                        </PhotoView>
                                                    </PhotoProvider>
                                                    {/* <div className={moduleCss.img}><img src={ item.img}></img></div> */}
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div key={index} className={moduleCss.card_item}>
<PhotoProvider>
                                                        <PhotoView src={item.img_2}>
                                                            <div>
                                                            <img src={item.img} alt="" />

                                                            </div>
                                                        </PhotoView>
                                                    </PhotoProvider>
                                                    <div className={moduleCss.text}>
                                                        <div className={moduleCss.text_title}>{item.title}</div>
                                                        <div className={moduleCss.desc} dangerouslySetInnerHTML={{ __html: item.desc }}/>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        
                                    })
                                }
                            </div>
                        </div>
                    )
                
                })
            }
            <Foot/>
        </>
    )
}

