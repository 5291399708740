import cs from "classnames"
import moduleCss from "./index.module.scss";

import { Modal, Button, Timeline, } from 'antd';

import arrowora_png from "@/assets/img/inquery/arrow.png"


export default function Part(props) {
    const lineData = [
        {
            title: 'Trailer',
            left: { title: 'PANYU', time: '06-25', },
            right: { title: 'GUANGZHOU', time: '07-25', },
            distance: '100',
        },
        {
            title: 'Barge: Guangzhou',
            left: { title: 'GUANGZHOU', time: '06-25', },
            right: { title: 'FOSHAN', time: '07-25', },
            distance: '100',
        },
        {
            title: 'Shipping companies：One',
            left: { title: 'FOSHAN', time: '06-25', },
            right: { title: 'Istanbul', time: '07-25', },
            distance: '100',
        },
        {
            title: 'Trailer',
            left: { title: 'Istanbul', time: '06-25', },
            right: { title: 'Destination', time: '07-25', },
            distance: '100',
        },
    ]
    const showModal = () => {
        props.setIsModalOpen(true);
    };

    const handleOk = () => {
        props.setIsModalOpen(false);
    };

    const handleCancel = () => {
        props.setIsModalOpen(false);
    };


    const detailCostData = [
        {title: 'Unit price', value: '$1000',},
        {title: 'Number', value: '1',},
        {title: 'Tax refund rate', value: '10%',},
        {title: 'Single piece cost', value: '10%',},
        {title: 'Customs clearance tax rate', value: '10%',},
        {title: 'Anti dumping tax rate', value: '10%',},
        {title: 'Overall cost', value: '$10,000',},
    ]
    return (<>
        <div className={cs(moduleCss.part, 'outpart')}>
                {lineData.map((e, i) => {
                    return (<div className={cs(moduleCss.partOne)} key={i}>
                    <div className={moduleCss.dotwrap}>
                            <div className={moduleCss.left}>
                                <div></div>
                            </div>
                            <div className={moduleCss.right}>{e.title}</div>
                    </div>
                    <div className={moduleCss.down}>
                        <div className={moduleCss.line}></div>
                        <div className={moduleCss.right}>
                            <div className="bold">
                                <div>{e.left.title}</div>
                                <div>{e.left.time}</div>
                            </div>
                            <div className="arrow">
                                <img src={ arrowora_png} />
                                <div>{e.distance}km</div>
                            </div>
                            <div className="bold">
                                <div>{e.right.title}</div>
                                <div>{e.right.time}</div>
                            </div>
                        </div>
                    </div> 
                </div>)
                })}
            </div>
    </>)
}