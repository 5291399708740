import http from "@/util/http";

export function getPortToPortListApi(params) {
    return http.get(
      "/point2point/list",
      params
    );
}

export function getPortListApi(params) {
    return http.get("/port/list", {
      params
    });
}
  
export function getPortToPortRouteApi(params) {
    return http.get(
      "/point2point/routing",
      {
        params
      }
    );
  }