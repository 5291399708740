import moduleCss from "./index.module.scss";
import logo_down from "@/assets/img/logo_down.png";
import chat_png from "@/assets/img/wechat.png";
import loc_png from "@/assets/img/loc.png";

export default function Header() {
    const footerPart = {
        menus: ['Home','Product','Solutions','About Us',],
        loc: '3rd Floor,Yuanzheng Science Park,Bantian Street,Longgang District,Shenzhen,China',
        pri: ['隐私政策','粤ICP备2024185077号-1',]
    }
    return (
        <footer>
            <img src={logo_down} className={moduleCss.logo}></img>
            <div className={moduleCss.menus}>
                {footerPart.menus.map((item, index) => {
                    return (<>
                        <div key={index} className={moduleCss.item}>
                        <div className={moduleCss.text}>{item}</div>
                        {index != 3 ? <div className={moduleCss.line}></div> : null}
                        </div>
                    </>)
                })
                }
            </div>
            <div className={moduleCss.bottom}>
                <div className={moduleCss.left}>
                    <img src={loc_png} ></img>
                    <div>{ footerPart.loc}</div>
                </div>
                <img src={chat_png}></img>
            </div>
        </footer>
    )
}