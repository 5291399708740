import PC from './pc/ship';
// import Mobile from './h5/ship';

export default function App() { 
    let flag = window.navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|mbian|Windows Phone)/i
    );
    if(flag){
        return <PC />
    } else {
        return <PC />
    }
}