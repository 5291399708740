import { Input, Collapse, } from "antd";
import moduleCss from "./home.module.scss";
import cs from "classnames"
import { Swiper, SwiperSlide, } from 'swiper/react';
import { Autoplay, Pagination, } from 'swiper/modules';

import Head from "./../header"
import Foot from "./../footer"

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "./swiper.css"

import s1 from "@/assets/img/service/s1.png"
import s2 from "@/assets/img/service/s2.png"
import s3 from "@/assets/img/service/s3.png"
import s4 from "@/assets/img/service/s4.png"
import s5 from "@/assets/img/service/s5.png"

import icon1 from "@/assets/img/service/ts1.png"
import icon2 from "@/assets/img/service/ts2.png"
import icon3 from "@/assets/img/service/ts3.png"
import icon4 from "@/assets/img/service/ts4.png"
import icon5 from "@/assets/img/service/ts5.png"

import f_icon1 from "@/assets/img/friends/s1.png"
import f_icon2 from "@/assets/img/friends/s2.png"
import f_icon3 from "@/assets/img/friends/s3.png"
import f_icon4 from "@/assets/img/friends/s4.png"
import f_icon5 from "@/assets/img/friends/s5.png"
import f_icon6 from "@/assets/img/friends/s6.png"
import f_icon7 from "@/assets/img/friends/s7.png"
import f_icon8 from "@/assets/img/friends/s8.png"
import f_icon9 from "@/assets/img/friends/s9.png"
import f_icon10 from "@/assets/img/friends/s10.png"


import search_icon_png from "@/assets/img/search.png"
import arrow_png from "@/assets/img/arrow.png"


export default function App() {
    const bannerPart = {
        h1: 'Global Supply Chain Solutions',
        h2: 'As a global digital logistics expert, we offers consultative services covering sea freight,trucking, customs clearance, insurance, overseas warehousing, and destination services',
        btn_text: 'Consult now',
        img: arrow_png,
    }

    const servicePart = {
        h1: 'Our Services',
        h2: 'services covering sea freight, trucking, customs declaration&clearance, insurance and overseas warehousing',
        blocks:
        [
            {
                t1: 'Global Supply Chain Solutions',
                t2: 'We provide customized supply chain solutions to meet the various needs of customers',
                img: s1,
            },
            {
                t1: 'Sea Freight',
                t2: 'We provide FCL between China & Turkey，all ports are covered',
                img: s2,
            },
            {
                t1: 'Bilateral Trucking',
                t2: 'We provide trucking services including factory loading & discharge',
                img: s3,
            },
            {
                t1: 'Bilateral Customs declaration and clearance',
                t2: 'We have wealth of powerful customs broker resources',
                img: s4,
            },
            {
                t1: 'Bilateral Storage',
                t2: 'We provide oversea warehousing through digitalized management',
                img: s5,
            },
        ],
    }
    const aiPart = {
        h1: 'Our  Advantages',
        h2: 'services covering sea freight, trucking, customs declaration&clearance, insurance and overseas warehousing',
        menus: [
            {
                img: icon1,
                title: 'Favourable price',
            },
            {
                img: icon2,
                title: 'Risk Management',
            },
            {
                img: icon3,
                title: 'One-Stop Solutions',
            },
            {
                img: icon4,
                title: 'Strong Business Team',
            },
            {
                img: icon5,
                title: 'Strong IT Team',
            },
            
        ],
        btn1: 'Consult now',
        btn2: 'Learn more',
        items: [
            {
                h1: 'Favourable price',
                h2: 'We are providing 2%-5% lower compare to market price',
                img: icon1,
                ul: [
                    'Lowest sea freight price',
                    'Fixed Distination Fee',
                    'We have serval branches in China and Turkey, to provide most cost-effective services',
                ],
            },
            {
                h1: 'Risk Management',
                h2: 'We are providing free supply chain diagnosis service to help customer reduce risk.',
                img: icon2,

                ul: [
                    'We provide extra insurance to protect our customer rights and interests',
                    'All cargo data is real-time monitored and traceable online,',
                    'All risk will be alerted by platform in time, provide security alerts for your cargos.',
                ],
            },
            {
                h1: 'One-Stop Solutions',
                h2: 'We provide customized precise and efficient supply chain solutions to meet the various needs of customers',
                img: icon3,

                ul: [
                    'Trucking and customs declaration at loading place',
                    'Container Booking',
                    'Customs clearance and trucking at destination place',
                    'Warehouse management',
                ],
            },
            {
                h1: 'Strong Business Team',
                h2: 'Our Business team with 26 years of Sea Freight experience.',
                img: icon4,

                ul: [
                    'Long-term and stable partnerships with top shipping companies',
                    'Stable cooperation with container trucking companies, covering major ports in China.',
                    'Several branches in China & Turkey',
                ],
            },
            {
                h1: 'Strong IT Team',
                h2: '17 Years experience in Digtial Supply Chain IT Platform  &  8 Years experience in Artificial Intelligence Algorithm Research.',
                img: icon5,

                ul: [
                    'Our Platform are offering free tools as Cargo Tracking, Container Usage Checking, Vessel Schedule, Port status Checking.',
                    'Our Platform had established EDI & API Connection with top shipping companies',
                    'AI algorithm help to forecast of shipping schedule',
                ],
            },
        ],
    }

    const getItems = (items) => {
        let rst = items.map((e, index) => {
            return {
                key: index + 1,
                label: (<div className={moduleCss.top}>
                    <img src={e.img}></img>
                    <div>
                        <p>{e.h1}</p>
                        <p>{e.h2}</p>
                    </div>
                </div>),
                children: (<div className={moduleCss.ul}>{e.ul.map((item, index) => {
                    return (<div key={index} className={moduleCss.li}>
                        <div className={moduleCss.dot}></div>
                        <div className={moduleCss.text1}>{ item}</div>
                    </div>)
                 })}</div>),
            }
        })
        return rst

    }

    
    const parterPart = {
        h1: 'Our Partner',
        h2: 'services covering sea freight, trucking, customs declaration&clearance, insurance and overseas warehousing',
        blocks: [
            f_icon1,
            f_icon2,
            f_icon3,
            f_icon4,
            f_icon5,
            f_icon6,
            f_icon7,
            f_icon8,
            f_icon9,
            f_icon10,],
    }
    

    const searchPart = {
        left: ['Location', 'Freight', 'Rates', 'Quote',],
        placeholder: 'Please input your quote',
    }

    const tradePart = {
        h1: 'Terms of Trade',
        h2: 'services covering sea freight, trucking, customs declaration&clearance, insurance and overseas warehousing',
        menus: ['FOB','CIF','EXW','DDU','DDP', 'DAP'],
    }
    return (<>
        <Head/>
        <div className={moduleCss.bannerandsearch}>
            <div className={moduleCss.banner}>
                <div className={moduleCss.text}>
                    <p>{ bannerPart.h1}</p>
                    <p>{ bannerPart.h2}</p>
                    <div className={moduleCss.btn}>
                        <div>{ bannerPart.btn_text}</div>
                        <img src={bannerPart.img}></img>
                    </div>
                </div>
            </div>
        </div>
        <div className={moduleCss.search1}>
            <div className={moduleCss.search}>
                    <div className={moduleCss.top}>
                        {searchPart.left.map((item, index) => {
                            return (<div key={index} className={cs(moduleCss.item, {[moduleCss._active]: index == 0})}>
                                <div className={moduleCss.text}>{item}</div>
                                <div className={moduleCss.line}></div>
                            </div>)
                        })
                        }
                    </div>
                    <Input placeholder={searchPart.placeholder} suffix={ <img src={search_icon_png} />} />
            </div>
            <div className={moduleCss.service}>
                <div className={moduleCss.title}>
                    <p>{ servicePart.h1}</p>
                    <p>{ servicePart.h2}</p>
                </div>
            
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    pagination={{
                        dynamicBullets: true,
                    }}
                        modules={[Autoplay, Pagination,]}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                            }}
                    className="sph5"
                >
                    {servicePart.blocks.map((item, index) => { 
                        return (
                            <SwiperSlide key={index}>
                            <div className={moduleCss.slideItem} style={{ backgroundImage: `url(${item.img})`}}>
                                    <p>{ item.t1}</p>
                                    <p>{ item.t2}</p>
                            </div>
                    </SwiperSlide>
                        )
                    })}
                    
                </Swiper>
            </div>
        </div>
        
        <div className={moduleCss.ai}>
            <div className={moduleCss.text}>
                <p>{ aiPart.h1}</p>
                <p>{ aiPart.h2}</p>
            </div>
            {
                <Collapse items={getItems(aiPart.items)} expandIconPosition='end'  defaultActiveKey={['1']} />
            }
        </div>
        <div className={moduleCss.parterPart}>
            <div className={moduleCss.title}>
                <p>{ parterPart.h1}</p>
                <p>{ parterPart.h2}</p>
            </div>
            <div className={moduleCss.blocks}>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}                
                    modules={[Autoplay, Pagination,]}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                          }}
                pagination={{
                    dynamicBullets: true,
                    }}
                className="sph6"
              >
                  <SwiperSlide>
                        <div className={moduleCss.slideItem}>
                            <div className={moduleCss.imgrow}>
                                <div>
                                <img src={parterPart.blocks[0]}></img>

                                </div>
                                <div>
                                <img src={parterPart.blocks[1]}></img>

                                </div>
                            </div>
                            <div className={moduleCss.imgrow}>
                                <div>
                                <img src={parterPart.blocks[2]}></img>

                                </div>
                                <div>

                                <img src={parterPart.blocks[3]}></img>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={moduleCss.slideItem}>
                            <div className={moduleCss.imgrow}>
                                <div>

                                <img src={parterPart.blocks[4]}></img>
                                </div>
                                <div>
                                <img src={parterPart.blocks[5]}></img>

                                </div>
                            </div>
                            <div className={moduleCss.imgrow}>
                                <div>

                                <img src={parterPart.blocks[6]}></img>
                                </div>
                                <div>
                                <img src={parterPart.blocks[7]}></img>

                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={moduleCss.slideItem}>
                            <div className={moduleCss.imgrow}>
                                <div>
                                <img src={parterPart.blocks[8]}></img>

                                </div>
                                <div>
                                <img src={parterPart.blocks[9]}></img>

                                </div>
                            </div>
                        </div>
                  </SwiperSlide>
              </Swiper>
        
            </div>
        </div>
        <div className={moduleCss.tradePart}>
            <div className={moduleCss.top}>
                <p>{ tradePart.h1}</p>
                <p>{ tradePart.h2}</p>
            </div>
            <div className={moduleCss.menus}>
                {tradePart.menus.map((item, index) => {
                    return (<div key={index} className={cs(moduleCss.item, {[moduleCss._active]: index == 0})}>
                        <div className={moduleCss.title}>{item}</div>
                        <div className={moduleCss.line}></div>
                    </div>)
                })
                }
            </div>
        </div>
        <Foot/>
    </>)
}