import axios from "axios";
import toast from "react-hot-toast";

let reqInstance = axios.create({
    // baseURL: "http://192.168.1.56:30200/hangyun/app",
    baseURL: "https://gateway.tradecloudai.com/hangyun/app",
    
    headers: {
        // common: {
        //     lang: lang,
        //     token: sessionStorage.getItem('userAuthToken'),
        //     timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        // },
        'Content-Type': 'application/json',
    },
    // timeout: 5000,
});


function request(method, url, params, options = {},){
    return new Promise((resolve,reject)=>{
        let data = {}
        if(method ==='get') data = {params}
        if (method === 'post') data = { data: params }
        Object.assign(reqInstance.defaults.headers, {token: sessionStorage.getItem('userAuthToken')})
        reqInstance({
            url,
            method,
            ...data
        }).then((res) => {
            resolve(res.data);
        }).catch((error)=>{
            toast.error(error.msg);
        })
    })
}

function get(url,params,options={}){
    return request('get', url,params,options,)
}
function post(url,params,options={}){
    return request('post', url,params,options,)
}


const axiosRequest = {
    get,
    post,
}
export default axiosRequest